.App-ul-tech {
  display: flex;
  flex-direction: column;
  /* overflow: auto; */
}

h3 {
  margin-left: 15px;
}

h4 {
  margin-left: 25px;
}

h2 {
  margin-left: 5px;
}

@media (min-width: 400px) {
  .App-ul-tech {
    display: flex;
    height: 300px;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
@media (min-width: 600px) {
  .App-ul-tech {
    display: flex;
    height: 200px;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
