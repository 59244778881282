.App {
  /* text-align: center; */
  background-color: #282c34;
}

.App-ul {
  overflow: hidden;
  display: flex;
}

.Nav-li {
  display: inline-block;
  margin-right: 10px;
}

.App-li {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
}

.App-logo {
  height: 40vmin;
}

.App-navContainer {
  /* display: flex;
  justify-content: center;
  overflow: auto; */
}

@media (min-width: 1px) and (max-width: 599px) {
  .Portfolio-image {
    height: 40vh;
    width: 100%;
  }
}

@media (min-width: 600px) {
  .Portfolio-image {
    height: 70vh;
    width: 100%;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background-color: #282c34;
}

.App-link {
  color: #810000;
}

.App-href {
  color: #810000;
}

.App-aboutText {
  margin-left: 25px;
  margin-right: 25px;
}

.App-text {
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
tr {
  color: #fff;
}

.table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  color: #fff;
}
